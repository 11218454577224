import { contentSpotTypes, umbraco } from '../../../lib/api';
import { ContentSpotSettings } from '../../../lib/api/models/umbraco/content-spot';
import { addDays, addHours } from 'date-fns';

function isDSTInDenmark(): boolean {
    const now = new Date();
    let lastSundayInMarch = new Date(now.getFullYear(), 2, 31);
    while (lastSundayInMarch.getDay() !== 0) {
        lastSundayInMarch = addDays(lastSundayInMarch, -1);
    }
    let lastSundayInOctober = new Date(now.getFullYear(), 9, 31);
    while (lastSundayInOctober.getDay() !== 0) {
        lastSundayInOctober = addDays(lastSundayInOctober, -1);
    }
    lastSundayInOctober = addHours(lastSundayInOctober, 1);
    if (now < lastSundayInMarch || now > lastSundayInOctober) return false;
    return true;
}

export function filterSpotsBasedOnDateAndHideSettings(spotList: ContentSpotSettings[]): ContentSpotSettings[] {
    return spotList.filter((x) => {
        if (x.hideBlock) return false;
        const fromDate = new Date(x.fromDate);
        const toDate = new Date(x.toDate);
        const localNow = addHours(new Date(), isDSTInDenmark() ? 2 : 1);
        if (fromDate > localNow) return false;
        if (toDate.toLocaleDateString('da-DK').split('.').join('/') !== '1/1/1' && toDate < localNow) return false; //January 1st year 1 is default date outputted by Umbraco when no date is set
        return true;
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const filterSpotsInUnknownPageType = async (page: umbraco.Page) => {
    if (
        page.contentTypeAlias === 'plusRootPage' ||
        page.contentTypeAlias === 'starPlusRootPage' ||
        page.contentTypeAlias === 'fordPlusRootPage' ||
        page.contentTypeAlias === 'hireRootPage' ||
        page.contentTypeAlias === 'contentPage' ||
        page.contentTypeAlias === 'shopCategoryPage' ||
        page.contentTypeAlias === 'hesselRootPage' ||
        page.contentTypeAlias === 'carSaverRootPage'
    ) {
        page.filteredContentSpots = filterSpotsBasedOnDateAndHideSettings(page.contentSpotsSettings);
    }
};

export const getSpotOpacity = (spotOpacity: contentSpotTypes.SpotOpacity): number => {
    switch (spotOpacity) {
        case 'High':
            return 0.9;
        case 'Medium':
            return 0.7;
        case 'Low':
            return 0.5;
        default:
            return 1;
    }
};
